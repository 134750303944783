jQuery(document).ready(function($) {

    $('.main-slider__carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.main-slider__nav',
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnFocus: true,
        pauseOnHover: true,
        pauseOnSwipe: true,
        pauseOnChange: true,
        dots: true,
        arrows: true
    });

    $('.main-slider__nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.main-slider__carousel',
        dots: true,
        fade: true,
        arrows: true,
        focusOnSelect: true,
        pauseOnFocus: true,
        pauseOnHover: true
    });

    $('.highlight-slider').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]
    });

    $('.product-detail-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500
    });

	$('.highlight-slider__slide__inner a').attr('target','_blank');

});