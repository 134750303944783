window.addEventListener('load', function(){
	window.cookieconsent.initialise({
		revokeBtn: "<div class='cc-revoke'></div>",
		type: "opt-in",
		position: "top",
		static: true,
		palette: {
			popup: {
				background: "#334f5b",
				text: "#fff"
			},
			button: {
				background: "#5795a5",
				text: "#fff"
			}
		},
		content: {
			message: "Wir verwenden Cookies, um unsere Webseite für Sie möglichst benutzerfreundlich zu gestalten.",
			link: "Weitere Informationen",
			allow: "Cookies erlauben",
			deny: "Ablehnen",
			href: "https://www.teubel-kurz.com/datenschutz.html"
		},
		onInitialise: function(status) {
			if(status == cookieconsent.status.allow) myScripts();
		},
		onStatusChange: function(status) {
			if (this.hasConsented()) myScripts();
		}
	})
});

function myScripts() {


	// Google Analytics, you need to change 'UA-00000000-1' to your ID
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

	ga('create', 'UA-852211-23', 'auto');
	ga('set', 'anonymizeIp', true);
	ga('send', 'pageview');


}